<template>
  <v-row>
    <v-col cols="12" lg="6">
      <payment-currency-form v-model="obCustomer" />
    </v-col>
    <v-col cols="12" lg="6">
      <payment-payment-term-form v-model="obCustomer" />
    </v-col>
    <v-col cols="12" lg="6" xl="3">
      <form-field-text
        v-model.number="obCustomer.credit_limit"
        input-type="number"
        label="credit.limit"
      />
    </v-col>
    <v-col cols="12" lg="6" xl="3">
      <currency-select v-model="obCustomer.currency_limit_id" company-only />
    </v-col>
    <v-col cols="12" lg="3">
      <price-list-select v-model="priceListId" autoselect />
    </v-col>
    <v-col v-if="isCustomer" cols="12" lg="3">
      <vendor-collector-select v-model="obCustomer.vendor_collector_id" />
    </v-col>
    <v-col cols="12" lg="6">
      <discount-fields v-model="discounts" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { DiscountData } from "@planetadeleste/vue-mc-gw";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";

import PaymentCurrencyForm from "@/modules/customers/components/PaymentCurrencyForm.vue";
import PaymentPaymentTermForm from "@/modules/customers/components/PaymentPaymentTermForm.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import DiscountFields from "@/modules/customers/components/DiscountFields.vue";
import PriceListSelect from "@/modules/pricelists/components/PriceListSelect.vue";
import { get, isArray, map, set } from "lodash";
import VendorCollectorSelect from "@/modules/vendorcollectors/components/VendorCollectorSelect.vue";

@Component({
  components: {
    VendorCollectorSelect,
    PriceListSelect,
    PaymentPaymentTermForm,
    PaymentCurrencyForm,
    CurrencySelect,
    DiscountFields,
  },
})
export default class CustomersPayment extends Mixins(CustomerMixin) {
  get currencies() {
    return this.getData("currencies", []);
  }

  get currencyListId() {
    return this.currencies.length
      ? map(this.currencies, (obCurrency) => obCurrency.id)
      : [];
  }

  get discounts() {
    return this.getData("discounts");
  }

  set discounts(arDiscounts: DiscountData[]) {
    this.setData("discounts", arDiscounts);
  }

  get priceListId(): number {
    return get(this.obConfig, "price_list_id", 0) as number;
  }

  set priceListId(sValue: number) {
    set(this.obConfig, "price_list_id", sValue);
    this.setData("config", this.obConfig);
  }

  get isCustomer() {
    return this.obCustomer.type === 1 || this.obCustomer.type === 3;
  }

  mounted() {
    if (isArray(this.obConfig)) {
      this.setData("config", {}, true);
    }
  }
}
</script>
